import React from "react"
import { Link, graphql, useStaticQuery, navigate } from "gatsby"
import {
  EListingCardLayouts,
  ListingCard,
} from "@organic-return/foundation-react/src/components/Listing/ListingCard"
import { EListingVitalsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingVitals"
import { Search } from "@organic-return/foundation-react/src/components/Search/Search"
import { SiteContext } from "../common/SiteContext"

const FilteredListingsSearch = ({ module }: any) => {
  const data = useStaticQuery(graphql`
    query  {
        allAgilityFilteredListingsSearch {
          nodes {
            contentID
            listings {
              id
              baths
              address {
                city
                county
                postal
                text
              }
              beds
              majorArea
              minorArea              
              flags
              mlsID
              price
              sqft
              status              
              subType
              subdivision
              exclusive              
              defaultImage {                
                url
              }
              path
              location {
                lat
                lng
              }              
              hasVirtualTour              
              rooms
              parkingFeatures
              newlyBuilt              
            }                       
          }
        }
    }
  `)

  let listingsSet = data.allAgilityFilteredListingsSearch.nodes.find((node: any) => {
    return node.contentID == module.contentID
  })?.listings?.sort((a: any, b: any) => { return a.price === b.price ? 0 : (a.price < b.price ? 1 : -1) }) ?? []

  if (module.customFields.includeRentals) {
    let rentalsSet = data.allAgilityFilteredListingsSearch.nodes.find((node: any) => {
      return node.contentID == module.contentID
    })?.rentals?.sort((a: any, b: any) => { return a.price === b.price ? 0 : (a.price < b.price ? 1 : -1) }) ?? []

    listingsSet = listingsSet.concat(rentalsSet)
  }

  // Small card size option (default)
  let cardClass = "w-[480px]"
  let cardImageWidth = 480
  // Large card size option
  if (module.customFields?.cardSize == "large") {
    cardClass = "w-[640px]"
    cardImageWidth = 640
  }

  // Configurable results per page defaults to 20
  let pageSize = module.customFields?.pageSize || 20
  return (
    <SiteContext.Consumer>{
      value => {
        return (
          <Search
            index={listingsSet}
            pageSize={pageSize}
            pageSizeOpts={[pageSize * 1, pageSize * 2, pageSize * 3, pageSize * 4]}
            pagerBottom={true}
            showCount={true}
            useMap={true}
            mapClick={item => navigate(item.path)}
            renderItem={(item, index) => {
              return (
                <ListingCard
                  key={index}
                  listing={item}
                  layout={EListingCardLayouts.vertical}
                  className={`${cardClass} max-w-full`}
                  maxWidthImage={cardImageWidth}
                  linkImage={true}
                  linkFunc={props => {
                    return (
                      <Link
                        to={props.href ?? ""}
                        className={`text-inherit ${props.className}`}
                        key={props.key}
                        title={props.title}
                      >
                        {props.children}
                      </Link>
                    )
                  }}
                  {...value.globalListingCardProps}
                  vitalsLayout={
                    module.customFields?.cardLayout ||
                    EListingVitalsLayout.column2Border1
                  }
                />
              )
            }}
            formBackgroundImage={module.customFields.formBackgroundImage?.url}
            title={module.customFields.formTitle}
            defaultSort={module.customFields.sortBy}
            {...value.listingSearchProps}
          />
        )
      }}
    </SiteContext.Consumer>
  )
}

export default FilteredListingsSearch
